import React, { useContext } from "react";
import Navbar from "../navbar/navbar.js";
import { ThemeContext } from "../../context/ThemeContext";
import Footer from "../footer/footer.js";
import Particles from "react-particles-js";
import CookieConsent from "react-cookie-consent";

import "./style.scss";

const Layout = ({ children }) => {
  const theme = useContext(ThemeContext);

  function closeMenu() {
    theme.setMenuOpen(false);
  }

  return (
    <>
      <Navbar />
      <div className="layout" role="button" tabIndex="0" onClick={closeMenu} onKeyPress={closeMenu}>
        <Particles
          style={{ position: "fixed", zIndex: -1 }}
          params={{
            particles: {
              color: {
                value: "#00A7E1",
              },
              number: {
                value: 5,
              },
              size: {
                value: theme.isMobile ? 50 : 150,
                random: true,
                anim: {
                  enable: false,
                  speed: 80,
                  size_min: 0.7,
                  sync: false,
                },
              },
              shape: {
                type: "circle",
              },
              // opacity: {
              //   random: false,
              //   anim: {
              //     enable: true,
              //     speed: 0.2,
              //     opacity_min: 0.1,
              //     sync: false,
              //   },
              // },
            },
          }}
        />
        {children}
      </div>
      <Footer />
      <CookieConsent
        style={{ background: "#00A7E1", fontFamily: "Averta-Regular"}}
        buttonStyle={{
          background: "white",
          color: "#00a7e1",
          fontSize: "0.9rem",
          borderRadius: "25px",
          padding: "10px 15px",
        }}
        buttonText="Accetto"
      >
        Il sito utilizza i cookie per funzionare. Utilizzando i nostri servizi
        acconsenti all'uso dei cookie.
        <a
          target="_blank"
          rel="noreferrer"
          href="http://docs.acmeproduzioni.it/cau-website/cookie-policy-it.html"
        >
          <span style={{ fontSize: "0.8rem", color:"white"}}> Maggiori informazioni</span>
        </a>
      </CookieConsent>
    </>
  );
};

export default Layout;
